'use client';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'next/image';
import clsx from 'clsx';
import searchSvg from '@/public/icons/search.svg';

const Search = () => {
    const router = useRouter();
    const pathname = usePathname();
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        if (!pathname.includes('/sok/')) {
            setInputValue('');
        }
    }, [pathname]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const query = inputValue.trim();

        if (query) {
            router.push(`/sok/${query}`);

            if (isFormVisible) toggleFormVisibility();
        }
    };

    const toggleFormVisibility = () => {
        setIsFormVisible(prevState => !prevState);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <div className={clsx('flex items-center', { 'w-full': isFormVisible })}>
            <form
                className={clsx(
                    'flex justify-between rounded-lg overflow-hidden bg-white shadow-innerBorder max-w-[400px]',
                    isFormVisible ? 'md:mx-auto' : 'md:hidden',
                )}
                onSubmit={handleSubmit}
            >
                <input
                    type="text"
                    className="w-full m-2 rounded-lg outline-none text-gray text-18 pl-20"
                    placeholder="Hva leter du etter?"
                    name="query"
                    value={inputValue}
                    onChange={handleChange}
                />
                <button
                    className="btn btn-primary min-w-102 md:min-w-72"
                    type="submit"
                >
                    Søk
                </button>
            </form>
            <Image
                src={searchSvg}
                alt="search"
                className={clsx(
                    'w-18 h-18 fill-white cursor-pointer hidden md:block',
                    { 'md:hidden': isFormVisible },
                )}
                onClick={toggleFormVisibility}
            />
        </div>
    );
};

export default Search;
